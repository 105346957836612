import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-4">
                <p>
                    <strong>iOpos Academia</strong> informa que tornem a portar la{" "}
                    <strong>MASTERCLASS D'ÀMBIT A</strong> en directe.
                </p>

                <p>
                    Una formació totalment renovada i actualitzada en base a la propera
                    convocatòria.
                </p>

                <p>
                    <strong>El curs s'impartirà:</strong>
                </p>
                <ul>
                    <li>
                        <strong>Dilluns 3 de febrer</strong> de 18.00 a 22.00h
                    </li>
                </ul>

                <p>
                    La classe es farà en directe mitjançant <strong>Zoom</strong>.
                </p>

                <p>
                    La sessió quedarà gravada al campus fins al dia <strong>15 de febrer</strong>{" "}
                    per poder veure-ho en cas de no poder assistir a la sessió o simplement per
                    veure-ho de nou.
                </p>

                <p>
                    La masterclass consta d'un repàs intens dels conceptes més importants i
                    habituals de l'examen d'aquest àmbit.
                </p>

                <p>
                    <strong>Únic pagament de 39,99 €</strong>
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/I5mUSaRUu1c"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per superar les oposicions de Mossos d'Esquadra amb les nostres MÀSTER CLASS intensives, actualitzades i dissenyades per triomfar a l’examen del 15 de febrer 2025. Aprèn els conceptes clau en directe i accedeix a les gravacions fins al dia de la prova"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
